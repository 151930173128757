<template>
	<w-data-table :headers="headers" hide-actions :items="value">
		<template v-slot:items="{ index: serviceIndex, item: serviceData }">
			<td class="text-xs-center">
				<v-avatar :color="serviceData.catalog_service.category.color" size="32px">
					{{ serviceData.catalog_service.category.abbreviation }}
				</v-avatar>
			</td>
			<td v-text="serviceData.catalog_service.title" />
			<td>
				<w-layout align-center fill-height justify-center row>
					<w-select
						class="ma-0 pa-0"
						hide-details
						:items="frequencies"
						item-text="name"
						:loading="frequenciesLoading"
						return-object
						:value="serviceData.frequency ? serviceData.frequency : defaultFrequency"
						@input="setFrequency(serviceIndex, $event)"
					>
					</w-select>
				</w-layout>
			</td>
			<td>
				<w-date-picker :value="serviceData.first_due_date" :max="project.end" :min="project.start" @input="setFirstDueDate(serviceIndex, $event)">
					<template v-slot:activator="{ on }">
						<w-layout align-center fill-height justify-center row v-on="on">{{ $d(new Date(serviceData.first_due_date)) }}</w-layout>
					</template>
				</w-date-picker>
			</td>
			<td class="text-xs-center">
				<w-layout v-if="!serviceData.frequency || serviceData.frequency.id === 1" align-center justify-center row>
					<w-btn :disabled="serviceData.quantity <= 1" icon="remove" mini small @click="decrementServiceQuantity(serviceIndex)" />
					<w-flex mx-2 shrink>
						{{ serviceData.quantity }}
					</w-flex>
					<w-btn icon="add" mini small @click="incrementServiceQuantity(serviceIndex)" />
				</w-layout>
			</td>
		</template>
	</w-data-table>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'
import Project from '@/classes/Offers/Project'

export default {
	name: 'ServicesForm',
	mixins: [ProjectsManagerModuleGuard],
	props: {
		project: {
			required: true,
			type: Project
		},
		value: {
			default: () => [],
			required: false,
			type: Array
		}
	},
	data: function () {
		return {
			defaultFrequency: null,
			headers: [
				{
					align: 'center',
					sortable: false,
					text: this.$tc('projects.categories', 1)
				},
				{
					align: 'center',
					text: this.$t('name'),
					value: 'catalog_service.title'
				},
				{
					align: 'center',
					sortable: false,
					text: this.$t('projects.frequency')
				},
				{
					align: 'center',
					text: this.$t('projects.service.first_due_date'),
					value: 'first_due_date'
				},
				{
					align: 'center',
					text: this.$t('quantity'),
					value: 'quantity'
				}
			],
			frequencies: [],
			frequenciesLoading: false
		}
	},
	created: function () {
		this.listFrequencies()
	},
	methods: {
		decrementServiceQuantity: function (serviceIndex) {
			const newVal = [...this.value]
			newVal[serviceIndex].quantity--
			this.$emit('input', newVal)
		},
		incrementServiceQuantity: function (serviceIndex) {
			const newVal = [...this.value]
			newVal[serviceIndex].quantity++
			this.$emit('input', newVal)
		},
		listFrequencies: function () {
			this.frequenciesLoading = true
			return this.service
				.listFrequencies()
				.then(frequencies => {
					this.frequencies = frequencies
					this.defaultFrequency = frequencies[0]
				})
				.finally(() => {
					this.frequenciesLoading = false
				})
		},
		setFirstDueDate: function (serviceIndex, date) {
			const newVal = [...this.value]
			newVal[serviceIndex].first_due_date = date
			this.$emit('input', newVal)
		},
		setFrequency: function (serviceIndex, frequency) {
			const newVal = [...this.value]
			newVal[serviceIndex].frequency = frequency
			if (frequency.key !== 'none') {
				newVal[serviceIndex].quantity = 1
			}
			this.$emit('input', newVal)
		}
	}
}
</script>
